import { ActionPerformed, PushNotificationSchema } from "@capacitor/push-notifications";
import { bus } from "@/modules/eventBus";

export default {
	async handleNotification(notification: PushNotificationSchema) {
		console.log("[SH Debug] Push notification received: ", JSON.stringify(notification));
	},
	async handleNotificationAction(notification: ActionPerformed) {
		console.log("[SH Debug] Push notification action performed",  JSON.stringify(notification));
		if(notification.actionId === 'tap' && notification.notification.data && notification.notification.data && notification.notification.data.communicationId){
			bus.emit('modal:communications:viewer:retrieve', notification.notification.data.communicationId)
		}
	}
}
