import {RouteRecordRaw} from "vue-router";
import {RoutePermission} from "@/router/index";

export const organizationRoutes: RouteRecordRaw[] = [
    {
        path: "/organizations/:organizationId",
        name: "organization-overview",
        props: true,
        component: () => import("@/views/organizations/OrganizationOverview.vue"),
        meta: {
            pageName: 'Organization Overview',
            //permission: RoutePermission.Access,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**'
        }
    },
    {
        path: "/organizations/:organizationId/schedule",
        name: "organization-schedule",
        props: true,
        component: () => import("@/components/Entity/SchedulingTab.vue"),
        meta: {
            pageName: 'Organization Schedule',
            //permission: RoutePermission.Access,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/schedule'
        }
    },
    {
        path: "/organizations/:organizationId/onboarding",
        name: "organization-onboarding",
        props: true,
        component: () => import("@/components/Onboarding/OrganizationOnboarding.vue"),
        meta: {
            pageName: 'Organization Onboarding',
            //permission: RoutePermission.Access,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/onboard'
        }
    },
    {
        path: "/organizations/:organizationId/communications",
        name: "organization-communications",
        props: true,
        component: () => import("@/components/Entity/CommunicationsTab.vue"),
        meta: {
            pageName: 'Organization Communications',
            //permission: RoutePermission.Access,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/communications'
        }
    },
    {
        path: "/organizations/:organizationId/productions",
        name: "organization-productions",
        props: true,
        component: () => import("@/components/Entity/ProductionsTab.vue"),
        meta: {
            pageName: 'Organization Productions',
            //permission: RoutePermission.Manage,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/productions'
        }
    },
    {
        path: "/organizations/:organizationId/availability",
        name: "organization-availability",
        props: true,
        component: () => import("@/components/Entity/AvailabilityTab.vue"),
        meta: {
            pageName: 'Organization Availability',
            //permission: RoutePermission.Manage,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/availability'
        }
    },
    {
        path: "/organizations/:organizationId/settings",
        name: "organization-settings",
        props: true,
        component: () => import("@/components/Entity/SettingsTab.vue"),
        meta: {
            pageName: 'Organization Settings',
            //permission: RoutePermission.Manage,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/settings'
        },
        children: [
            {
                path: "",
                name: "organization-settings-home",
                props: true,
                component: () => import("@/components/Entity/EntitySettingsMenuMobile.vue"),
                meta: {
                    pageName: 'Organization Settings General',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/general'
                }
            },
            {
                path: "general",
                name: "organization-settings-general",
                props: true,
                component: () => import("@/components/Entity/Settings/GeneralSettings.vue"),
                meta: {
                    pageName: 'Organization Settings General',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/general'
                }
            },
            {
                path: "logo",
                name: "organization-settings-logo",
                props: true,
                component: () => import("@/components/Entity/Settings/LogoSettings.vue"),
                meta: {
                    pageName: 'Organization Settings Logo',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/logo'
                }
            },
            {
                path: "venues",
                name: "organization-settings-venues",
                props: true,
                component: () => import("@/components/SelfContained/VenueSettings.vue"),
                meta: {
                    pageName: 'Organization Settings Venues',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/venues'
                }
            },
            {
                path: "report-templates",
                name: "organization-settings-report-templates",
                props: true,
                component: () => import("@/components/Entity/Reports/Templates/ReportTemplatesTable.vue"),
                meta: {
                    pageName: 'Organization Settings Report Templates',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/report-templates',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "report-templates/:reportTemplateId",
                name: "organization-settings-report-template-editor",
                props: true,
                component: () => import("@/components/Entity/Reports/Templates/ReportTemplateEditor.vue"),
                meta: {
                    pageName: 'Organization Settings Report Template Editor',
                    //permission: RoutePermission.Manage,
                    permissionId: 'organizationId',
                    friendlyPath: '/organizations/**/settings/report-templates/**',
                    boxed: true,
                    fullWidth: true,
                }
            }
        ]
    },
    {
        path: "/organizations/:organizationId/people",
        name: "organization-people",
        props: true,
        component: () => import("@/components/Entity/PeopleTab.vue"),
        meta: {
            pageName: 'Organization People',
            // permission: RoutePermission.Access,
            permissionId: 'organizationId',
            friendlyPath: '/organizations/**/people'
        }
    }
];
