import { apiClient } from "@/modules/apiClient";
import { User } from "@/models/user/User";
import { CreateAccountCommand } from "@/models/user/CreateAccountCommand";
import { AxiosPromise, AxiosResponse } from "axios";
import { AvatarViewModel } from "@/models/AvatarViewModel";
import { AccountCommand } from "@/models/user/AccountCommand";
import { RemovePersonCommand } from "@/models/people/RemovePersonCommand";
import { Invitation } from "@/models/user/Invitation";
import { RequestAccessCommand } from "@/models/user/RequestAccessCommand";
import { InvitationResponseCommand } from "@/models/user/InvitationResponseCommand";
import { NotificationItem } from "@/models/notifications/Notification";
import { SendFeedbackCommand } from "@/models/SendFeedbackCommand";
import { UserMeasurement } from "@/models/user/UserMeasurement";
import MeasurementSettings from "@/components/SelfContained/MeasurementSettings.vue";
import { UpdateAccountTermsCommand } from "@/models/user/UpdateAccountTermsCommand";
import { UserMetadata } from "@/models/user/UserMetadata";
import { appApiClient } from "@/modules/appApiClient";
import {PhoneNumber} from "@/models/user/PhoneNumber";
import {UserOnboardingStatus} from "@/models/user/UserOnboardingStatus";

// tslint:disable-next-line:typedef
export const UserService = {
	fetch(): Promise<AxiosResponse<User>> {
		return appApiClient.get("/accounts");
	},
	fetchMetadata(): Promise<AxiosResponse<UserMetadata>> {
		return appApiClient.get("/accounts/metadata");
	},
	fetchOnboardStatus(): Promise<AxiosResponse<UserOnboardingStatus>> {
		return appApiClient.get("/accounts/onboarding-status")
	},
	reconcile(): Promise<AxiosResponse> {
		return appApiClient.post("/accounts/reconcile");
	},
	fetchInvitations(): Promise<AxiosResponse<Array<Invitation>>> {
		return apiClient.get("/invitations");
	},
	fetchNotifications(): Promise<AxiosResponse<Array<NotificationItem>>> {
		return appApiClient.get("/accounts/notifications");
	},
	markReadNotifications(notificationIds: Array<string>): Promise<AxiosResponse> {
		return appApiClient.put("/accounts/notifications", { notificationIds: notificationIds });
	},
	create(command: CreateAccountCommand): Promise<AxiosResponse<User>> {
		return appApiClient.post("/accounts", command);
	},
	update(command: AccountCommand): Promise<AxiosResponse> {
		return appApiClient.put("/accounts", command);
	},
	updateTerms(command: UpdateAccountTermsCommand): Promise<AxiosResponse> {
		return appApiClient.put("/accounts/terms", command);
	},
	uploadPhoto(formData: any): Promise<AxiosResponse<AvatarViewModel>> {
		return appApiClient.put("/accounts/photo", formData, { headers: { "Content-Type": "multipart/form-data" } });
	},
	removePhoto(): Promise<AxiosResponse> {
		return appApiClient.delete("/accounts/photo");
	},
	removeSelf(command: RemovePersonCommand): Promise<AxiosResponse> {
		return apiClient.put("/people/remove/self", command);
	},
	requestAccess(command: RequestAccessCommand): Promise<AxiosResponse> {
		return apiClient.post("/requests", command);
	},
	respondToInvitation(command: InvitationResponseCommand): Promise<AxiosResponse> {
		return apiClient.put("/invitations", command);
	},
	sendFeedback(command: SendFeedbackCommand): Promise<AxiosResponse> {
		return appApiClient.post("/system/feedback", command);
	},
	fetchMeasurements(): Promise<AxiosResponse<UserMeasurement>> {
		return apiClient.get("/measurements");
	},
	upsertMeasurements(command: UserMeasurement): Promise<AxiosResponse<UserMeasurement>> {
		return apiClient.put("/measurements", command);
	},
	fetchMeasurementsQuery: async () => {
		const response = await apiClient.get<UserMeasurement>(`/measurements`);
		return response.data;
	},
};
