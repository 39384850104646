import { defineStore } from "pinia";
import { UserOnboardingStatus } from "@/models/user/UserOnboardingStatus";
import { ProductionOnboardingStatus } from "@/models/productions/ProductionOnboardingStatus";
import { OrganizationOnboardingStatus } from "@/models/organizations/OrganizationOnboardingStatus";
import { UserService } from "@/services/UserService";
import { useUser } from "@/store/pinia/userStore";
import { ProductionService } from "@/services/ProductionService";
import { OrganizationService } from "@/services/OrganizationService";
import { MembershipRole } from "@/models/enums/Role";
import { OnboardProgressDto } from "@/models/OnboardProgress";
import { MembershipStatus } from "@/models/enums/MembershipStatus";
import { CreateUserProfileDto } from "@/models/profiles/CreateUserProfileDto";

export const useOnboardings = defineStore("onboardings", {
	state: () => {
		return {
			user: {} as UserOnboardingStatus,
			productions: [] as Array<ProductionOnboardingStatus>,
			organizations: [] as Array<OrganizationOnboardingStatus>,
			progress: [] as Array<OnboardProgressDto>,
			userProfile: {
				title: "",
				biography: "",
				latitude: 0,
				longitude: 0,
				city: "",
				state: "",
				country: "",
				goals: [] as Array<number>,
				profileTypes: [] as Array<number>,
				enableAutoFollow: true,
				subscribeToNewsletter: true,
			} as CreateUserProfileDto
		};
	},
	actions: {
		fetchUser() {
			UserService.fetchOnboardStatus().then(response => {
				this.user = response.data;
			});
		},
		getProduction(productionId: string): ProductionOnboardingStatus | undefined {
			return this.productions.find(x => x.productionId == productionId);
		},
		isProductionComplete(productionId: string): boolean {
			const productionOnboarding = this.productions.find(x => x.productionId == productionId);
			if (productionOnboarding) {
				const { hasPeople, hasGroups, hasCallTypes, hasVenues, hasPhoto } = productionOnboarding;
				const remainingItems = [hasPeople, hasGroups, hasCallTypes, hasVenues, hasPhoto].filter((value) => !value);
				return remainingItems.length === 0;
			}
			return false;
		},
		isOrganizationComplete(organizationId: string): boolean {
			const organizationOnboarding = this.organizations.find(x => x.organizationId == organizationId);
			if (organizationOnboarding) {
				const { hasPeople, hasVenues, hasPhoto } = organizationOnboarding;
				const remainingItems = [hasPeople, hasVenues, hasPhoto].filter((value) => !value);
				return remainingItems.length === 0;
			}
			return false;
		},
		getOrganization(organizationId: string): OrganizationOnboardingStatus | undefined {
			return this.organizations.find(x => x.organizationId == organizationId);
		},
		async fetchEntityOnboardings() {
			const user = useUser();
			// Fetch Production Onboarding
			for (let i = 0; i < user.productions.length; i++) {
				if (user.productions[i].role === MembershipRole.Member || user.productions[i].status !== MembershipStatus.Active) continue;
				const productionId = user.productions[i].productionId;
				const onboardingStatus = await ProductionService.fetchOnboardStatus(productionId);
				const existingProduction = this.productions.find(x => x.productionId === productionId);
				if (existingProduction) {
					Object.assign(existingProduction, onboardingStatus.data);
				} else {
					this.productions.push(onboardingStatus.data);
				}
			}

			// Fetch Organization Onboarding
			for (let i = 0; i < user.organizations.length; i++) {
				if (user.organizations[i].role === MembershipRole.Member || user.organizations[i].status !== MembershipStatus.Active) continue;
				const organizationId = user.organizations[i].organizationId;
				const onboardingStatus = await OrganizationService.fetchOnboardStatus(organizationId);
				const existingOrganization = this.organizations.find(x => x.organizationId === organizationId);
				if (existingOrganization) {
					Object.assign(existingOrganization, onboardingStatus.data);
				} else {
					this.organizations.push(onboardingStatus.data);
				}
			}
		},
	},
	getters: {
		remainingForUser(): number {
			const { hasAvatar, hasMemberships, hasEmergencyContacts } = this.user;
			const remainingItems = [hasAvatar, hasMemberships, hasEmergencyContacts].filter((value) => !value);
			return remainingItems.length;
		},
	},
});
