import { __awaiter } from "tslib";
import { useUser } from "@/store/pinia/userStore";
import { onMounted, onUnmounted, defineComponent, ref } from "vue";
import { PushNotifications } from "@capacitor/push-notifications";
import { Preferences } from "@capacitor/preferences";
import { DeviceService } from "@/services/DeviceService";
import { isPlatform } from "@ionic/vue";
import { useApplication } from "@/store/pinia/applicationStore";
import PushNotificationHandler from "./modules/PushNotificationHandler";
export default defineComponent({
  name: "ApplicationWrapper",
  setup() {
    const userStore = useUser();
    const deviceToken = ref("");
    let permStatus = ref({
      receive: "prompt-with-rationale"
    });
    const init = () => __awaiter(this, void 0, void 0, function* () {
      if (isPlatform("capacitor")) {
        const application = useApplication();
        //console.log("[SH Debug] Initializing push notifications");
        PushNotifications.checkPermissions().then(result => {
          //console.log("[SH Debug] Permission status on init: ", result);
          permStatus.value = result;
          if (application.pushNotificationPermission !== 'later') {
            application.pushNotificationPermission = result.receive;
          }
          // If the user already granted us notification permissions, register the device
          if (permStatus.value.receive === "granted") {
            //console.log('[SH Debug] Registering device - user already granted previously')
            PushNotifications.register();
          }
        });
        yield PushNotifications.addListener("registration", token => __awaiter(this, void 0, void 0, function* () {
          console.info("[SH Debug] Registration token: ", token.value);
          deviceToken.value = token.value;
          const azureRegistrationId = yield Preferences.get({
            key: "azureRegistrationId"
          });
          application.pushNotificationPermission = 'granted';
          DeviceService.add({
            platform: isPlatform("ios") ? 0 : 1,
            token: token.value,
            application: 0,
            azureRegistrationId: (azureRegistrationId === null || azureRegistrationId === void 0 ? void 0 : azureRegistrationId.value) || ""
          }).then(response => {
            //console.log("[SH Debug] Device registered");
            response.data.azureRegistrationId && Preferences.set({
              key: "azureRegistrationId",
              value: response.data.azureRegistrationId
            });
          });
        }));
        yield PushNotifications.addListener("registrationError", err => {
          console.error("[SH Debug] Registration error: ", err.error);
        });
        yield PushNotifications.addListener("pushNotificationReceived", notification => {
          PushNotificationHandler.handleNotification(notification);
        });
        yield PushNotifications.addListener("pushNotificationActionPerformed", notification => {
          PushNotificationHandler.handleNotificationAction(notification);
        });
      }
    });
    onMounted(() => {
      init();
    });
    onUnmounted(() => {
      if (isPlatform('capacitor')) {
        PushNotifications.removeAllListeners();
      }
    });
    return {
      userStore
    };
  },
  mounted() {
    this.userStore.fetchUser();
    //console.log('[SH] ApplicationWrapper Mounted', this.userStore.fetchComplete)
  }
});