import auth0 from "@/auth";
import {useMockAuth} from "@/modules/authWrapper";
import {isPlatform} from "@ionic/vue";
import {Browser} from "@capacitor/browser";
import {unref} from "vue";
import {watchEffectOnceAsync} from "@/utilities/watchEffect";

export async function AuthGuard(to: any) {
    const isCypressTest = !!localStorage.getItem('auth0Cypress');
    const {
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        loginWithRedirect
    } = isCypressTest ? useMockAuth() : auth0;

    const fn = async () => {
        //console.log('unref(isAuthenticated)', unref(isAuthenticated));
        //console.log('isCypressTest', isCypressTest);
        if (unref(isAuthenticated)) {
            //console.log('will return true');
            return true;
        } else {
            if (isPlatform("capacitor")) {
                await loginWithRedirect({
                    appState: {target: to.fullPath},
                    async openUrl(url) {
                        await Browser.open({url});
                    },
                });
            } else {
                await loginWithRedirect({
                    appState: {target: to.fullPath},
                });
            }
        }
        /*await getAccessTokenSilently().then(async () => {
            if (isPlatform("capacitor")) {
                await loginWithRedirect({
                    appState: {target: to.fullPath},
                    async openUrl(url) {
                        await Browser.open({url});
                    },
                });
            } else {
                await loginWithRedirect({
                    appState: {target: to.fullPath},
                });
            }
        });*/
        //console.log('will return false')
        return false;
    };

    if (!unref(isLoading)) {
        return fn();
    }

    await watchEffectOnceAsync(() => !unref(isLoading));

    return fn();
}
