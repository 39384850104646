import {RouteRecordRaw} from "vue-router";

export const userSettingRoutes: RouteRecordRaw[] = [
    {
        path: '/settings',
        name: 'user-settings',
        component: () => import('@/components/User/UserSettingsTab.vue'),
        children: [
            {
                path: "",
                name: "user-settings-home",
                component: () => import("@/components/User/Settings/UserSettingsMenuMobile.vue"),
                meta: {
                    pageName: 'User Settings Home',
                    friendlyPath: '/user/settings',
                    showDetailsHeader: true,
                    header: 'Settings',
                    hideBottomNav: true,
                }
            },
            {
                path: "conflicts",
                name: "user-settings-conflicts",
                props: true,
                component: () => import("@/components/User/Settings/UserConflictSettings.vue"),
                meta: {
                    pageName: 'User Conflicts',
                    friendlyPath: '/user/settings/conflicts',
                    showDetailsHeader: true,
                    header: 'Conflicts',
                    actionButton: 'Add',
                    actionButtonEmits: 'modal:conflicts:add',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "photo",
                name: "user-settings-photo",
                props: true,
                component: () => import("@/components/User/Settings/UserProfilePhotoSettings.vue"),
                meta: {
                    pageName: 'User Photo',
                    friendlyPath: '/user/settings/photo',
                    showDetailsHeader: true,
                    header: 'Profile Photo',
                    fullWidth: true,
                }
            },
            {
                path: "emergency-contacts",
                name: "user-settings-emergency-contacts",
                props: true,
                component: () => import("@/components/User/Settings/UserEmergencyContactsCard.vue"),
                meta: {
                    pageName: 'User Emergency Contacts',
                    friendlyPath: '/user/settings/emergency-contacts',
                    showDetailsHeader: true,
                    header: 'Emergency Contacts',
                    actionButton: 'Add',
                    actionButtonEmits: 'modals:emergency:add',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "contact-methods",
                name: "user-settings-contact-methods",
                props: true,
                component: () => import("@/components/User/Settings/UserContactMethodSettings.vue"),
                meta: {
                    pageName: 'User Contact Methods',
                    friendlyPath: '/user/settings/contact-methods',
                    showDetailsHeader: true,
                    header: 'Contact Methods',
                    actionButton: 'Add',
                    actionButtonEmits: 'contactmethods-add',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "measurements",
                name: "user-settings-measurements",
                props: true,
                component: () => import("@/components/User/Settings/UserSettingsMeasurements.vue"),
                meta: {
                    pageName: 'User Measurements',
                    friendlyPath: '/user/settings/measurements',
                    showDetailsHeader: true,
                    header: 'Measurements',
                    actionButton: 'Save',
                    actionButtonEmits: 'measurements:save',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "conflicts",
                name: "user-settings-notifications",
                props: true,
                component: () => import("@/components/User/Settings/UserDeviceSettings.vue"),
                meta: {
                    pageName: 'User Notifications',
                    friendlyPath: '/user/settings/notifications',
                    showDetailsHeader: true,
                    header: 'Notifications',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "general",
                name: "user-settings-general",
                props: true,
                component: () => import("@/components/User/Settings/UserGeneralSettings.vue"),
                meta: {
                    pageName: 'User Settings',
                    friendlyPath: '/user/settings',
                    showDetailsHeader: true,
                    header: 'Personal Information',
                    actionButton: 'Save',
                    actionButtonEmits: 'user-settings-save',
                    boxed: true,
                    fullWidth: true,
                }
            }
        ]
    },
];
