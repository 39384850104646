import { RouteRecordRaw } from "vue-router";

export const profileRoutes: RouteRecordRaw[] = [
	{
		path: "/u/:id?/",
		name: "user-profile",
		props: true,
		component: () => import("@/views/profiles/UserProfile.vue"),
		meta: {
			fullWidth: true,
			boxed: true,
			hideBottomNav: false,
			showDetailsHeader: true,
			header: "View Profile",
		},
		children: [
			{
				path: "",
				name: "user-profile-about",
				props: true,
				component: () => import("@/views/profiles/user/UserProfileAbout.vue"),
				meta: {
					pageName: "User Profile: About",
					friendlyPath: "/u/**",
				},
			},
			{
				path: "credits",
				name: "user-profile--credits",
				props: true,
				component: () => import("@/views/profiles/user/UserProfileCredits.vue"),
				meta: {
					pageName: "User Profile: Credits",
					friendlyPath: "/u/**/credits",
				},
			},
		],
	},
	{
		path: "/u/:id?/edit",
		name: "edit-user-profile",
		props: true,
		meta: {
			fullWidth: true,
			boxed: true,
			showDetailsHeader: true,
			header: "Edit Profile",
			actionButton: "Save",
			actionButtonEmits: "profiles:user:save",
			hideBottomNav: true,
		},
		component: () => import("@/views/profiles/user/EditUserProfile.vue"),
		children: [{
			path: "/u/:id?/edit/about",
			name: "edit-user-profile--about",
			props: true,
			meta: {
				fullWidth: true,
				boxed: true,
				showDetailsHeader: true,
				header: "Edit Profile",
				actionButton: "Save",
				actionButtonEmits: "profiles:user:save",
				hideBottomNav: true,
				pageName: "Edit User Profile: About",
				friendlyPath: "/u/**/edit/about",
			},
			component: () => import("@/views/profiles/user/EditUserProfileAbout.vue"),
			children: [],
		},
			{
				path: "/u/:id?/edit/education",
				name: "edit-user-profile--education",
				props: true,
				meta: {
					fullWidth: true,

					boxed: true,
					showDetailsHeader: true,
					header: "Edit Education",
					actionButton: "Add",
					actionButtonEmits: "profiles:user:education:add",
					hideBottomNav: true,
					pageName: "Edit User Profile: Education",
					friendlyPath: "/u/**/edit/education",
				},
				component: () => import("@/views/profiles/user/EditProfileEducation.vue"),
				children: [],
			},

			{
				path: "/u/:id?/edit/credits",
				name: "edit-user-profile--credits",
				props: true,
				component: () => import("@/views/profiles/user/UserProfileCredits.vue"),
				meta: {
					pageName: "Edit User Profile: Credits",
					friendlyPath: "/u/**/edit/credits",
				},
			},
		],
	},
	{
		path: "/u/create",
		name: "create-user-profile",
		props: true,
		meta: {
			fullWidth: true,
			boxed: true,
			hideBottomNav: true,
		},
		component: () => import("@/views/profiles/user/CreateUserProfile.vue"),
		children: [
			{
				path: "",
				name: "create-user-profile--welcome",
				props: true,
				component: () => import("@/views/profiles/user/create/1CUPWelcome.vue"),
				meta: {
					pageName: "CUP: Welcome",
					friendlyPath: "/u/create",
				},
			},
			{
				path: "goals",
				name: "create-user-profile--goals",
				props: true,
				component: () => import("@/views/profiles/user/create/2CUPGoals.vue"),
				meta: {
					pageName: "CUP: Goals",
					friendlyPath: "/u/create/goals",
				},
			},
			{
				path: "profile-type",
				name: "create-user-profile--profile-type",
				props: true,
				component: () => import("@/views/profiles/user/create/3CUPProfileType.vue"),
				meta: {
					pageName: "CUP: Profile Type",
					friendlyPath: "/u/create/profile-type",
				},
			},
			{
				path: "general",
				name: "create-user-profile--general",
				props: true,
				component: () => import("@/views/profiles/user/create/4CUPGeneralProfile.vue"),
				meta: {
					pageName: "CUP: General Profile",
					friendlyPath: "/u/create/general",
				},
			},
			{
				path: "location",
				name: "create-user-profile--location",
				props: true,
				component: () => import("@/views/profiles/user/create/5CUPLocation.vue"),
				meta: {
					pageName: "CUP: Location",
					friendlyPath: "/u/create/location",
				},
			},
			{
				path: "complete",
				name: "create-user-profile--complete",
				props: true,
				component: () => import("@/views/profiles/user/create/6CUPComplete.vue"),
				meta: {
					pageName: "CUP: Complete",
					friendlyPath: "/u/create/complete",
				},
			},
		],
	},
];
