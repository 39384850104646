import axios from "axios";
import auth0 from "@/auth";
import { useMockAuth } from "@/modules/authWrapper";
import {isPlatform} from "@ionic/vue";
import {Browser} from "@capacitor/browser";
import { openReplayTracker } from "@/main";
import { WebAnalytics } from "@/modules/webAnalytics";

const isCypressTest = !!localStorage.getItem("auth0Cypress");
const { getAccessTokenSilently, loginWithRedirect } = isCypressTest ? useMockAuth() : auth0;

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE + "/backstage",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

apiClient.interceptors.request.use(
    async (config) => {
        try {
            const tokenResponse = await getAccessTokenSilently();

            if (config.headers) {
                config.headers["Authorization"] = `Bearer ${tokenResponse}`;
                if(openReplayTracker.isActive()){
                    config.headers["X-OpenReplay"] = openReplayTracker.getSessionURL({withCurrentTime: true}) || `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`;
                }
            }
            return config;
        } catch (err: any) {
            console.log('[SH] Authentication Interceptor Error', err)
            WebAnalytics.trackFlexible('Application Error', {
                message: 'Authentication Interceptor Error',
                errorString: JSON.stringify(err),
                errorMessage: err.error ? err.error : 'No error message'
            })
            if (err && err.error) {
                console.log('[SH] Auth Error', err.error, err)
            }
            if (err.error === 'missing_refresh_token' || err.error === 'invalid_grant') {
                if (isPlatform('capacitor')) {
                    await loginWithRedirect({
                        async openUrl(url) {
                            await Browser.open({url})
                        }
                    })
                } else {
                    await loginWithRedirect()
                }
            }
            if (err.error !== 'login_required') {
                return Promise.reject(err);
            } else {
                if (isPlatform("capacitor")) {
                    await loginWithRedirect({
                        async openUrl(url) {
                            await Browser.open({url});
                        },
                    });
                } else {
                    await loginWithRedirect();
                }
            }
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);
