import { RouteRecordRaw } from "vue-router";

export const productionRoutes: RouteRecordRaw[] = [
    {
        path: "/productions/:productionId",
        name: "production-overview",
        props: true,
        component: () => import("@/views/productions/ProductionOverview.vue"),
        meta: {
            pageName: 'Production Overview',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**'
        }
    },
    {
        path: "/productions/:productionId/onboarding",
        name: "production-onboarding",
        props: true,
        component: () => import("@/components/Onboarding/ProductionOnboarding.vue"),
        meta: {
            pageName: 'Production Onboarding',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/onboard'
        }
    },
    {
        path: "/productions/:productionId/schedule",
        name: "production-schedule",
        props: true,
        component: () => import("@/components/Entity/SchedulingTab.vue"),
        meta: {
            pageName: 'Production Schedule',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/schedule'
        }
    },
    {
        path: "/productions/:productionId/communications",
        name: "production-communications",
        props: true,
        component: () => import("@/components/Entity/CommunicationsTab.vue"),
        meta: {
            pageName: 'Production Communications',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/communications',
            boxed: true
        }
    },
    {
        path: "/productions/:productionId/reports",
        name: "production-reports",
        props: true,
        component: () => import("@/components/Entity/ReportsTab.vue"),
        meta: {
            pageName: 'Production Reports',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/reports',
            boxed: true,
            fullWidth: true,
        }
    },
    {
        path: "/productions/:productionId/reports/:reportId",
        name: "production-report",
        props: true,
        component: () => import("@/components/Entity/Reports/ReportTab.vue"),
        meta: {
            pageName: 'Production Report Home',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/reports/**',
            boxed: true,
            fullWidth: true,
            hideBottomNav: false,
            showDetailsHeader: true,
            header: "View Report",
        },
        children: [
            {
                path: "",
                name: "production-reports--viewer",
                props: true,
                component: () => import("@/components/Entity/Reports/ReportViewer.vue"),
                meta: {
                    pageName: 'Production Report Viewer',
                    //permission: RoutePermission.Access,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/reports/**',
                    boxed: true,
                    fullWidth: true,
                    hideBottomNav: true,
                    showDetailsHeader: true,
                    header: "View Report",
                },
            },
            {
                path: "comments",
                name: "production-reports--comments",
                props: true,
                component: () => import("@/components/Entity/Reports/ReportComments.vue"),
                meta: {
                    pageName: 'Production Report Comments',
                    //permission: RoutePermission.Access,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/reports/**',
                    boxed: true,
                    fullWidth: true,
                    hideBottomNav: true,
                    showDetailsHeader: true,
                    header: "View Report Comments",
                },
            },
            {
                path: "insights",
                name: "production-reports--insights",
                props: true,
                component: () => import("@/components/Entity/Reports/ReportInsights.vue"),
                meta: {
                    pageName: 'Production Report Insights',
                    //permission: RoutePermission.Access,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/reports/**',
                    boxed: true,
                    fullWidth: true,
                    hideBottomNav: true,
                    showDetailsHeader: true,
                    header: "View Report Insights",
                },
            },
            {
                path: "edit",
                name: "production-reports--editor",
                props: true,
                component: () => import("@/components/Entity/Reports/ReportEditor.vue"),
                meta: {
                    pageName: 'Production Report Editor',
                    //permission: RoutePermission.Access,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/reports/**/edit',
                    boxed: true,
                    fullWidth: true,
                    hideBottomNav: true,
                    showDetailsHeader: true,
                    header: "Edit Report",
                    actionButton: "Save",
                    actionButtonEmits: "reports:editor:update",
                },
            }
        ]
    },
    {
        path: "/productions/:productionId/drive/:folderId?",
        name: "production-drive",
        props: true,
        component: () => import("@/components/Entity/DriveTab.vue"),
        meta: {
            pageName: 'Production Drive',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/drive'
        }
    },
    {
        path: "/productions/:productionId/availability",
        name: "production-availability-calendar",
        props: true,
        component: () => import("@/components/Entity/AvailabilityTab.vue"),
        meta: {
            pageName: 'Production Availability',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/availability'
        }
    },
    {
        path: "/productions/:productionId/availability/calls",
        name: "production-availability-calls",
        props: true,
        component: () => import("@/components/Calendar/CallsTable.vue"),
        meta: {
            pageName: 'Production Calls',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/availability/calls'
        }
    },
    {
        path: "/productions/:productionId/availability/requests",
        name: "production-availability-requests",
        props: true,
        component: () => import("@/components/Entity/Scheduling/ProductionConflicts.vue"),
        meta: {
            pageName: 'Production Availability Requests',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/availability/requests'
        }
    },
    {
        path: "/productions/:productionId/settings",
        name: "production-settings",
        props: true,
        component: () => import("@/components/Entity/SettingsTab.vue"),
        meta: {
            pageName: 'Production Settings',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/settings'
        },
        children: [
            {
                path: "",
                name: "production-settings-home",
                props: true,
                component: () => import("@/components/Entity/EntitySettingsMenuMobile.vue"),
                meta: {
                    pageName: 'Production Settings',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/general'
                }
            },
            {
                path: "general",
                name: "production-settings-general",
                props: true,
                component: () => import("@/components/Entity/Settings/GeneralSettings.vue"),
                meta: {
                    pageName: 'Production Settings General',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/general'
                }
            },
            {
                path: "logo",
                name: "production-settings-logo",
                props: true,
                component: () => import("@/components/Entity/Settings/LogoSettings.vue"),
                meta: {
                    pageName: 'Production Settings Logo',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/logo'
                }
            },
            {
                path: "venues",
                name: "production-settings-venues",
                props: true,
                component: () => import("@/components/SelfContained/VenueSettings.vue"),
                meta: {
                    pageName: 'Production Settings Venues',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/venues'
                }
            },
            {
                path: "call-types",
                name: "production-settings-call-types",
                props: true,
                component: () => import("@/components/SelfContained/CallTypeSettings.vue"),
                meta: {
                    pageName: 'Production Settings Call Types',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/call-types'
                }
            },
            {
                path: "report-templates",
                name: "production-settings-report-templates",
                props: true,
                component: () => import("@/components/Entity/Reports/Templates/ReportTemplatesTable.vue"),
                meta: {
                    pageName: 'Production Settings Report Templates',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/report-templates',
                    boxed: true,
                    fullWidth: true,
                }
            },
            {
                path: "report-templates/:reportTemplateId",
                name: "production-settings-report-template-editor",
                props: true,
                component: () => import("@/components/Entity/Reports/Templates/ReportTemplateEditor.vue"),
                meta: {
                    pageName: 'Production Settings Report Template Editor',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/report-templates/**',
                    boxed: true,
                    fullWidth: true,
                }
            }
        ]
    },
    {
        path: "/productions/:productionId/people",
        name: "production-people",
        props: true,
        component: () => import("@/components/Entity/PeopleTab.vue"),
        meta: {
            pageName: 'Production People',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/people'
        }
    }
];
