import {createRouter, createWebHistory, Router, RouteRecordRaw} from "vue-router";
import {productionRoutes} from "@/router/ProductionRoutes";
import {organizationRoutes} from "@/router/OrganizationRoutes";
import {userRoutes} from "@/router/UserRoutes";
import {userSettingRoutes} from "@/router/UserSettingRoutes";
import {WebAnalytics} from "@/modules/webAnalytics";
import {useApplication} from "@/store/pinia/applicationStore";
import {AuthGuard} from "@/router/AuthGuard";
import { authGuard } from '@auth0/auth0-vue';
import { Capacitor } from "@capacitor/core";
import { profileRoutes } from "@/router/ProfileRoutes";

export enum RoutePermission {
	Access, Manage, Admin
}

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        components: {
            application: () => import("@/layout/Layout.vue")
        },
        children: [
            {
                path: "/auth",
                name: "authcallback",
                component: () => import("@/views/Auth/Callback.vue"),
                meta: {
                    pageName: 'Auth Callback',
                    //permission: RoutePermission.Access,
                    friendlyPath: '/callbacks/auth'
                }
            },
            {
                path: "/onboarding",
                name: "onboarding",
                component: () => import("@/components/Onboarding/OnboardingView.vue"),
                meta: {
                    pageName: 'Onboarding',
                    //permission: RoutePermission.Access,
                    friendlyPath: '/onboarding'
                }
            },
            {
                path: "/terms",
                name: "terms",
                component: () => import("@/components/User/TermsTab.vue"),
                meta: {
                    pageName: 'Terms',
                    //permission: RoutePermission.Access,
                    friendlyPath: '/terms'
                }
            },
            {
                path: "/sandbox",
                name: "sandbox",
                component: () => import("@/views/sandbox/ComponentTestView.vue"),
            },
            ...organizationRoutes,
            ...productionRoutes,
            ...userRoutes,
            ...userSettingRoutes,
            ...profileRoutes,
        ]
    },
    {
        name: 'sign-up',
        path: "/sign-up",
        components: {
            signup: () => import("@/views/Auth/CreateProfile.vue"),
            meta: {
                pageName: 'Sign Up',
                //permission: RoutePermission.Access,
                friendlyPath: '/sign-up'
            }
        }
    },
    {
        name: 'start',
        path: "/start",
        components: {
            start: () => import("@/views/Start.vue"),
            meta: {
                pageName: 'Start',
                //permission: RoutePermission.Access,
                friendlyPath: '/start'
            }
        }
    },
    {
        name: 'NotFound',
        path: '/:catchAll(.*)',
        component: () => import("@/views/NotFound.vue"),
        meta: {
            pageName: 'Not Found',
            friendlyPath: '/notfound'
        }
    }
			
];

const router: Router = createRouter({
	history: createWebHistory(),
	routes,
});

//router.beforeEach(authGuard);
router.beforeEach(AuthGuard);

// router.beforeEach((to, from, next) => {
//     if (to.name === 'authcallback') {
//         // console.log('is auth callback')
//         Auth.Instance.authorizationCallback(to.fullPath);
//         WebAnalytics.trackFlexible('Sign In', {})
//         setTimeout(function () {
//             next('/')
//             //next({path: decodeURIComponent(to.fullPath.split('state=')[1].split('&')[0])})
//         }, 1500);
//     } else if (to.path.indexOf('endsession') > -1) {
//         Auth.Instance.endSessionCallback()
//         next('/start');
//     } else {
//         next();
//     }
// });

// router.beforeEach((to, from, next) => {
//     // console.log('[SH] A', to)
//     if (to.name !== 'start') {
//         // console.log('[SH] B')
//         Auth.Instance.initComplete$.pipe(
//             filter(complete => complete),
//             switchMap(() => Auth.Instance.isAuthenticated$),
//             take(1))
//             .subscribe((isAuthenticated) => {
//                 // console.log('[SH] C', isAuthenticated)
//                 if (isAuthenticated) {
//                     Auth.Instance.getValidToken().then(token => {
//                         const applicationStore = useApplication()
//                         applicationStore.setAuthenticatedState(true)
//                         next();
//                         //console.log('token', token)
//                     }).catch(() => {
//                         // console.log('no valid token, send to sign in')
//                         Auth.Instance.signIn();
//                     })
//                 } else {
//                     next({path: '/start', query: {toPath: to.path}})
//                 }
//             });
//     } else {
//         // console.log('[SH] D')
//         next();
//     }
// })


/*router.beforeEach((to, from, next) => {
    console.log('[SH] ROUTER GUARD ' + to.fullPath)
    if (to.name === 'authcallback') {
        //console.log('\tis authcallback', decodeURIComponent(to.fullPath.split('state=')[1].split('&')[0]))

        Auth.Instance.authorizationCallback(to.fullPath);

        setTimeout(function () {
            next({path: decodeURIComponent(to.fullPath.split('state=')[1].split('&')[0])});
            const userStore = useUser()
            userStore.fetchUser()
        }, 500)

        /!*
        msalInstance.handleRedirectPromise().then((tokenResponse) => {
            if (tokenResponse) {
                const authState: AuthState = JSON.parse(<string>tokenResponse.state);
                const userStore = useUser();
                //console.log('authState', authState)
                //console.log('\treturnPath', authState.returnPath)
                msalInstance.setActiveAccount(tokenResponse.account);
                userStore.fetchUser()
                next({path: authState.returnPath});
            } else {
                next({path: '/'});
            }
        });*!/
    } else {
        /!*
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }
        */
//console.log('\t[SH] not callback')
//next();
// }
//});*/
/*
router.beforeEach((to, from, next) => {
    // console.log('[SH] B')
    //Check if the user is authenticated
    if (Auth.Instance.session.isAuthenticated) {
        // console.log('[SH] authenticated')
        next();
    } else {
        // console.log('[SH] NOT authenticated')
        Auth.Instance.signIn(undefined, to.path);
    }
    /!*
    if (msalService.isAuthenticated()) {
        next();
    } else {
        //console.log('router before invokeSignIn', to.path)
        msalService.invokeSignIn(to.path);
    }
     *!/
});*/

router.beforeEach((to, from, next) => {
	//console.log('[SH] Router To: ', to.name);
	const applicationStore = useApplication();
	applicationStore.setRoute(to);
	next();
});

router.beforeEach((to, from, next) => {
    //console.log('D')
    if (to.name !== 'authcallback' && to.name !== 'endsession') {
        WebAnalytics.view({
            platform: Capacitor.getPlatform(),
            pageName: to.meta.pageName as string,
            url: `${document.location.protocol}//${document.location.host}${to.fullPath}`,
            path: to.fullPath,
            fromPath: from.fullPath,
            friendlyPath: to.meta.friendlyPath as string,
            friendlyUrl: `${document.location.protocol}//${document.location.host}${to.meta.friendlyPath}`,
        });
    }
    next();
});

export default router;
