import {AxiosResponse} from "axios";
import {appApiClient} from "@/modules/appApiClient";
import { CreateDeviceRegistrationDto } from "@/models/device/CreateDeviceRegistrationDto";
import { DeviceRegistrationDto } from "@/models/device/DeviceRegistrationDto";

export const DeviceService = {
	add(command: CreateDeviceRegistrationDto): Promise<AxiosResponse<DeviceRegistrationDto>>{
		return appApiClient.post(`/device-registrations`, command)
	},
	getList(): Promise<AxiosResponse<Array<DeviceRegistrationDto>>>{
		return appApiClient.get(`/device-registrations`)
	},
}
