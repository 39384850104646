import { defineStore } from "pinia";
import { RouteLocationNormalized } from "vue-router";
import { useUser } from "@/store/pinia/userStore";

export const useApplication = defineStore("application", {
	state: () => {
		return {
			isLoaded: false,
			isAuthenticated: false,
			route: {} as RouteLocationNormalized | { name: "" } as any,
			pushNotificationPermission: "not-set",
			ui: {
				isDetails: false,
				activeRequestsTab: "requests",
				activeGlobalTab: "Home",
				activeEntityTab: "Dashboard",
				isEntityMode: false,
				isUserSettingsMode: false,
				activeUserSettingTab: "General",
				entityName: "",
				header: {
					title: "",
				},
				entity: {
					people: {
						tab: "members",
					},
					productions: {
						tab: "active",
					},
					drive: {
						tab: "list",
						canManage: false,
						currentFolderName: ''
					}
				},
				people: {
					tab: "members", //members, groups, requests
				},
				user: {
					communications: {
						tab: "",
					},
					scheduling: {
						tab: "",
					},
					memberships: {
						tab: "memberships", //memberships, requests
					},
					mobileSettingsTab: "unset",
				},
				calls: {
					selected: 0, // This is used with the call selection table, to link to the toolbar button
				},
			},
		};
	},
	actions: {
		setLoadingState: function(loadingState: boolean) {
			this.isLoaded = loadingState;
		},
		setAuthenticatedState: function(authenticatedState: boolean) {
			this.isAuthenticated = authenticatedState;
		},
		setRoute(route) {
			this.route = route;
		},
		changeGlobalTab(tabName: string) {
			this.ui.activeGlobalTab = tabName;
			this.ui.isUserSettingsMode = tabName === "Settings";
		},
		changeEntityTab(tabName: string) {
			this.ui.activeEntityTab = tabName;
		},
		changeUserSettingTab(tabName: string) {
			this.ui.activeUserSettingTab = tabName;
		},
		changeEntityMode(isEntityMode: boolean) {
			this.ui.isEntityMode = isEntityMode;
		},
		updateEntityName(name: string) {
			this.ui.entityName = name;
		},
		changePeopleTab(tab: string) {
			this.ui.people.tab = tab;
		},
		changeEntityProductionsTab(tab: string) {
			this.ui.entity.productions.tab = tab;
		},
		changeUserMembershipsTab(tab: string) {
			this.ui.user.memberships.tab = tab;
		},
		setUserMobileSettingsTab(tab) {
			this.ui.user.mobileSettingsTab = tab;
		},
	},
	getters: {
		peopleCurrentTab: (state) => {
			return state.ui.people.tab;
		},
		userMembershipsCurrentTab: (state) => {
			return state.ui.user.memberships.tab;
		},
		isEntityMode: (state) => {
			return state.route.name.indexOf("production") > -1 || state.route.name.indexOf("organization") > -1;
		},
		isProduction: (state) => {
			return state.route.name.indexOf("production") > -1;
		},
		isOrganization: (state) => {
			return state.route.name.indexOf("organization") > -1;
		},
		isArchived(state) {
			// Create an instance of useUser()
			const userStore = useUser();

			// Check if the route is for a production
			if (state.route.name.indexOf("production") > -1) {
				const productionId = (state.route as RouteLocationNormalized).params["productionId"] as string;
				// Find the production in the user's productions array
				const production = userStore.productions.find(p => p.productionId === productionId);
				// Return whether the production's entityStatus indicates it's archived
				return production && production.entityStatus === 2;  // Assuming 1 indicates archived
			}
			// Check if the route is for an organization
			else if (state.route.name.indexOf("organization") > -1) {
				const organizationId = (state.route as RouteLocationNormalized).params["organizationId"] as string;
				// Find the organization in the user's organizations array
				const organization = userStore.organizations.find(o => o.organizationId === organizationId);
				// Return whether the organization's entityStatus indicates it's archived
				return organization && organization.entityStatus === 2;  // Assuming 1 indicates archived
			}
			// Return false if the route is for neither
			return false;
		},
		productionId: (state): string => {
			if (state.route.name.indexOf("production") > -1) {
				return (state.route as RouteLocationNormalized).params["productionId"] as string;
			}
			return "";
		},
		organizationId: (state): string => {
			if (state.route.name.indexOf("organization") > -1) {
				return (state.route as RouteLocationNormalized).params["organizationId"] as string;
			}
			return "";
		},
		folderId: (state): string => {
			if (state.route.name.indexOf("drive") > -1) {
				if ((state.route as RouteLocationNormalized).params["folderId"]) {
					return (state.route as RouteLocationNormalized).params["folderId"] as string;
				} else {
					if (state.route.name.indexOf("production") > -1) {
						return (state.route as RouteLocationNormalized).params["productionId"] as string;
					}
					return "need production id";
				}
			}
			return "";
		},
		isRootFolder: (state): string => {
			if (state.route.name.indexOf("drive") > -1) {
				if ((state.route as RouteLocationNormalized).params["folderId"]) {
					return (state.route as RouteLocationNormalized).params["folderId"] as string;
				} else {
					if (state.route.name.indexOf("production") > -1) {
						return ""
					}
					return "need production id";
				}
			}
			return "";
		}
	},
});
