import { computed } from 'vue';

export function useMockAuth() {
    const isAuthenticated = computed(() => {
        const auth0Cypress = JSON.parse(<string>localStorage.getItem('auth0Cypress'));
        if (auth0Cypress && new Date().getTime() < auth0Cypress.expiresAt) {
            return true;
        }
        return false;
    });

    const isLoading = computed(() => {
        const auth0Cypress = JSON.parse(<string>localStorage.getItem('auth0Cypress'));
        if (auth0Cypress && new Date().getTime() < auth0Cypress.expiresAt) {
            return false;
        }
        return true;
    });

    const user = computed(() => {
        const auth0Cypress = JSON.parse(<string>localStorage.getItem('auth0Cypress'));
        if (auth0Cypress && new Date().getTime() < auth0Cypress.expiresAt) {
            return {
                // Mock user object, you can add more fields as needed
                sub: 'test_user_id'
            };
        }
        return null;
    });

    const getIdToken = async () => {
        const auth0Cypress = JSON.parse(<string>localStorage.getItem('auth0Cypress'));
        if (auth0Cypress && new Date().getTime() < auth0Cypress.expiresAt) {
            return auth0Cypress.idToken;
        }
        return null;
    };

    const getAccessTokenSilently = async () => {
        const auth0Cypress = JSON.parse(<string>localStorage.getItem('auth0Cypress'));
        if (auth0Cypress && new Date().getTime() < auth0Cypress.expiresAt) {
            return auth0Cypress.accessToken;
        }
        return null;
    };

    const loginWithRedirect = async () => {};
    const logout = async () => {};

    return {
        isAuthenticated,
        user,
        getIdToken,
        getAccessTokenSilently,
        isLoading,
        loginWithRedirect,
        logout
    };
}
