import { isPlatform } from "@ionic/vue";
import { App } from "@capacitor/app";
import auth0 from '@/auth';
import { Browser } from "@capacitor/browser";
import router from "@/router";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";

export default function(){
    if (isPlatform('capacitor')) {
        App.addListener('appUrlOpen', async (data: any) => {
            console.log('[SH Debug] appUrlOpen', data);
            if (data.url.includes('state') && (data.url.includes('code') || data.url.includes('error'))) {
                //console.log('[SH] Matches Auth Callback');
                auth0.handleRedirectCallback(data.url).then(() => {
                    //console.log('[SH] handleRedirectCallback promise');
                    const applicationStore = useApplication()
                    applicationStore.setAuthenticatedState(true)
                    const userStore = useUser();
                    setTimeout(function(){
                        userStore.fetchUser();
                        router.push({ name: 'user-overview' })
                    }, 1500);

                    // No-op on Android
                    Browser.close();
                }).catch(err => {
                    console.log('[SH] auth error', err)
                });
            }
            else {
                const slug = data.url.split('.com').pop();
                console.log('[SH Debug] slug', slug)
                // We only push to the route if there is a slug present
                if (slug) {
                    await router.push({
                        path: slug,
                    });
                }
            }
        });
    }
}
